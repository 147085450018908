<template>
	<v-container>
		<v-form ref="form" v-model="isvalid" lazy-validation>
			<v-card tile>
				<v-toolbar flat dark color="primary">
					<v-toolbar-title>New Collection Request</v-toolbar-title>
					<div class="flex-grow-1"></div>
				</v-toolbar>
				<v-card-text>
					<v-container grid-list-md>
						<v-layout wrap>
							<v-flex xs12 sm12 md12>
								<p style="font-size:12px; color:orange;line-height:0; margin:0">
									<v-icon color="orange" x-small>mdi-alert-circle-outline</v-icon>
									Please make sure to set the correct collection date before selecting Receipt Type.
								</p>
							</v-flex>
							<v-flex xs12 sm12 md4>
								<v-text-field
									label="Collection Number"
									v-model="collection_number"
									readonly
								>
								</v-text-field>
							</v-flex>
							<v-flex xs12 sm12 md4>
								<v-menu
										v-model="bill_date"
										:nudge-right="40"
										transition="scale-transition"
										offset-y
										min-width="290px"
										:close-on-content-click="false"
									>
										<template v-slot:activator="{ on }">
											<v-text-field
												label="Collection Date"
												v-model="collection_date"
												required
												return-masked-value
												v-on="on">
											</v-text-field>
										</template>
										<v-date-picker
											color="primary"
											v-model="collection_date"
											@change="bill_date = false"
										></v-date-picker>
									</v-menu>
							</v-flex>
							<v-flex xs12 sm6 md4>
								<v-text-field label=" Billing Month and Year" required v-model="month_year" @click="month_year_picker = true" hide-details />
								<v-dialog width="25%" v-if="month_year_picker" v-model="month_year_picker"> 
									<v-date-picker
										v-model="month_year"
										type="month"
										@change="month_year_picker = false; getData()"
									></v-date-picker>
								</v-dialog>
							</v-flex>
                            <v-flex xs12 sm12 md4>
								<v-autocomplete
									class="remove-underline"
									v-model="invoice_no"
									:items="filteredItems"
									editable
									hide-details
									item-text="name"
									item-value="billing_request_no"
									label="Invoice Number"
									:rules="defaultRules"
									@change="setDetails()"
								></v-autocomplete>
									<!-- :rules="defaultRules" -->
							</v-flex>
							<v-flex xs12 sm12 md4>
								<v-text-field v-model="payee_desc" readonly label="Payee" ></v-text-field>
							</v-flex>
							<v-flex xs12 sm12 md4>
								<v-text-field v-model="due_date" readonly label="Due Date" ></v-text-field>
							</v-flex>
							<v-flex xs12 sm12 md4>
								<v-text-field v-model="billing_amount" readonly label="Billing Amount" ></v-text-field>
							</v-flex>
							<v-flex xs12 sm12 md4>
								<v-select
									v-model="receipt_type"
									:items="receipt_types"
									editable
									item-text="name"
									item-value="id"
									:rules="defaultRules"
									label="Receipt Type"
									@change="getReceiptNumber()"
								>
								</v-select>
							</v-flex>
							<v-flex xs12 sm12 md4>
								<v-text-field :rules="defaultRules" v-model="receipt_number"  label="Receipt Number" ></v-text-field>
							</v-flex>
							<v-flex xs12 sm12 md4>
								<v-text-field :rules="defaultRules" v-model="collected_amount" label="Collected Amount" ></v-text-field>
							</v-flex>
							<!-- <v-btn color="primary" @click="viewReceipt()" v-if="invoice_no && collected_amount && receipt_type">View Receipt</v-btn> -->
						</v-layout>
					</v-container>
					<div class="container">
                        <div class="large-12 medium-12 small-12 cell">
							<h4>Supporting Documents</h4><br>
                            <label>
                                <input type="file" id="files" ref="files" multiple v-on:change="handleFileUploads()"/>
                            </label>
                        </div>
                    </div>
                    <v-container grid-list-md>
						<b>Collection Entries</b>
						<v-flex md12>
								<v-simple-table dense>
									<template v-slot:default>
										<thead>
										<tr>
											<th>#</th>
											<th>Department</th>
											<th>Branch</th>
											<th>Section</th>
											<th width="30%">Chart of Accounts</th>
											<th>Debit</th>
											<th>Credit</th>
											<th>Delete</th>
										</tr>
										</thead>
										<tbody>
										<tr
											v-for="(detail, detail_key) in details"
											:key="detail.detail_key"
										>
											<td>{{ detail_key + 1}}</td>
											<td>
											<v-autocomplete
												class="remove-underline"
												v-model="detail.department_id"
												:items="dataDepts"
												editable
												hide-details
												item-text="dept_desc"
												item-value="id"
												@change="getDeptData(detail.department_id, detail_key)"
											>
											</v-autocomplete>
											</td>
											<td>
											<v-autocomplete
												class="remove-underline"
												v-model="detail.branch_id"
												:items="branches"
												editable
												hide-details
												item-text="code_name"
												item-value="id"
											></v-autocomplete>
											</td>
											<td>
											<v-autocomplete
												class="remove-underline"
												v-model="detail.section_id"
												:items="detail.sections"
												v-on:focus="getDeptData(detail.department_id, detail_key)"
												editable
												hide-details
												item-text="section_name"
												item-value="section_id"
											></v-autocomplete>
											</td>
											<td>
											<v-autocomplete
												class="remove-underline"
												v-model="detail.account_code"
												:items="coaData"
												editable
												hide-details
												item-text="code_name"
												item-value="acct_code"
											></v-autocomplete>
											</td>
											<td>
											<v-text-field
												v-model="detail.debit_amount"
												:readonly="parseFloat((typeof detail.credit_amount ==='string' ? detail.credit_amount.replace(/,/g, '') : detail.credit_amount)) > 0 || detail.remarks === 'Tax Based Amount' || detail.remarks === 'Outright Amount'"
												type="text"
												class="input_number text-right amount"
												editable
												hide-details
											></v-text-field>
											</td>
											<td>
											<v-text-field
												v-model="detail.credit_amount"
												:readonly="parseFloat((typeof detail.debit_amount ==='string' ? detail.debit_amount.replace(/,/g, '') : detail.debit_amount)) > 0"
												type="text"
												class="input_number text-right amount"
												editable
												hide-details
											></v-text-field>
											</td>
											<td>
											<v-btn icon @click="removeDetail(detail_key)">
												<v-icon small color="red">delete</v-icon>
											</v-btn>
											</td>
										</tr>
										<tr>
											<td class="text-right" colspan="5">
											<b>TOTAL </b>
											</td>
											<td class="text-right">
											<b>{{overallDebit}}</b>
											</td>
											<td class="text-right">
											<b>{{overallCredit}}</b>
											</td>
											<td>&nbsp;</td>
										</tr>
										</tbody>
									</template>
									</v-simple-table>
                                <v-btn right dark color="primary" @click="addRow">
                                <v-icon>mdi-plus</v-icon>
                                <span>Add New Row</span>
                                </v-btn>
							</v-flex>
					</v-container>
				</v-card-text>
				<div class="text-center">
					<v-dialog
						v-model="viewor"
						width="50%"
					>
						<v-billing-or  :payee_data="payee_data" :collection_date="collection_date" :invoice_data="invoice_data" :collected_amount="collected_amount"></v-billing-or>
					</v-dialog>
				</div>
				<div class="text-center">
					<v-dialog
						v-model="viewar"
						width="50%"
					>
						<v-billing-ar :payee_data="payee_data" :collection_date="collection_date" :invoice_data="invoice_data" :collected_amount="collected_amount"></v-billing-ar>
					</v-dialog>
				</div>
				<div class="text-center">
			<v-dialog
				v-model="createDialog"
				persistent max-width="600"
			>
				<v-card>
					<v-card-title
						class="headline primary white--text"
						primary-title
					>
						Create Collection
					</v-card-title>
					<br>
					<v-card-text>
						Are you sure you want to submit the form?
					</v-card-text>

					<v-divider></v-divider>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="primary"
							text
							@click="save()"
						>
							Save
						</v-btn>
						<v-btn
							color="red"
							text
							@click="createDialog = false"
						>
							Cancel
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
    <v-snackbar
      v-model="snackbar"
      right
    >
      {{ error_message }}
      <v-btn
        color="pink"
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
		</div>
				<v-card-actions>
					<v-spacer></v-spacer>
						<v-btn text color="blue accent-4" @click="createDialog = true">Create Collection</v-btn>
						<v-btn dark color="red accent-4" @click="cancel()">Cancel</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { format, getYear, getMonth, parseISO } from 'date-fns';
import numeral from 'numeral';
import jsonToFormData from 'json-form-data';
import viewOR from '../viewOR.vue';
import viewAR from '../viewAR.vue';

export default {
	components: {
		'v-billing-or': viewOR,
		'v-billing-ar': viewAR,
	},
	data: () => ({
		viewor: false,
		viewar:false,
		upload_files: '',
		isvalid: true,
		module_types: [
			{code: 'F', name: 'File Maintenance'},
			{code: 'T', name: 'Transaction'},
			{code: 'R', name: 'Report'},
		],
		details: [
			{ department_id: '',
				branch_id: '',
				section_id: '',
				account_code: '',
				debit_amount: 0,
				credit_amount: 0,
			}
		],
		vat_category: '',
		vat_categories: [
			{ id: 1, name: 'VAT Inclusive' },
			{ id: 2, name: 'VAT Exclusive' },
			{ id: 3, name: 'VAT Exempt' },
		],
		bill_date: false,
		draftBilling : false,
		createDialog: false,
		payee_id: '',
		payee_desc: '',
		snackbar: false,
		error_message: '',
		collection_date: '',
		payment_term: 0,
		due_date: '',
		business_type: '',
		billing_amount: '',
		service_data: [],
		overallDebit: 0,
		overallCredit: 0,
		invoice_no: '',
		billing_id: 0,
		collected_amount: '',
		receipt_type: '',
		receipt_number: '',
		billing_details: [],
		defaultRules: [
			v => !!v || 'Required'
		],
		dataCoas: [],
		invoice_data: [],
		nextPageCoas: '',
		nextPageDepts: '',
		dataDepts: [],
		payee_data : [],
		month_year: '',
		month_year_picker: false,
		receipt_types: [{id: 2, name: 'Acknowledgement Receipt'}]
	}),

	computed: {
		...mapGetters({
			billings: 'trxbilling/billings',
			uncollected_billing: 'trxbilling/uncollectedBilling',
			currUser: 'auth/currUser',
			editedItem: 'trxcollection/editedItem',
			formTitle: 'trxcollection/formTitle',
			valid: 'trxcollection/valid',
			dialog: 'trxcollection/dialog',
			editedIndex: 'trxcollection/editedIndex',
			payees: 'payefile/payefiles',
			services: 'services/services',
			businessType: 'payefile/businessType',
			coaData: 'coa/coaData',
			coaItem: 'coa/coaCurr',
			branches: 'branch/branches',
			coas: 'coa/coaSubs',
			depts: 'dept/depts',
			sections: 'section/sections',
			or_number: 'trxdisb/or_number',
			ar_number: 'trxdisb/ar_number',
			collection_number: 'trxcollection/collectionNumber',
		}),
		filteredItems() {
			let app = this;
			let txn = app.billings.filter(det => (parseFloat(det.amount) - parseFloat(det.amount_collected) !== 0) && det.accounting_status === 7);
			txn.forEach(det => {
				det.name = det.billing_invoice_no + ' - ' + det.remarks;
			});
			return txn;
		}

	},
	watch: {
		details: {
			handler(val){
				val.map(det => {
					det.debit_amount = numeral(det.debit_amount).format('0,0.[00]');
					det.credit_amount = numeral(det.credit_amount).format('0,0.[00]');
				});
				let overallDebit = val.reduce((sum, details) => {
					if (details.debit_amount === '') {
						details.debit_amount = 0;
					}

					return sum + parseFloat(details.debit_amount.replace(/,/g, ''));
				}, 0);
				this.overallDebit = numeral(overallDebit).format('0,0.[00]');
				let overallCredit = val.reduce((sum, details) => {
					if (details.credit_amount === '') {
						details.credit_amount = 0;
					}

					return sum + parseFloat(details.credit_amount.replace(/,/g, ''));
				}, 0);
				this.overallCredit = numeral(overallCredit).format('0,0.[00]');
			},
			deep: true
		},
		payee_id: {
			handler(){
				this.payeeDetails();
			}
		},
		depts:{
			handler(){
				this.getDataDepts();
			}
		},
		coas: {
			handler(){
				this.getDataCoas();
			}
		},
	},
	mounted() {
		let date = format(new Date(), 'yyyy-MM-dd');
		let month = getMonth(parseISO(date)) + 1;
		let year = getYear(parseISO(date));
		let i = (month.toString().length == 2 ? '-' : '-0');
		this.month_year = year + i + month;
		this.$store.dispatch('payefile/getPayefileDropdown');
		this.$store.dispatch('services/getServices');
		//this.$store.dispatch('trxbilling/uncollectedBillings');
		this.$store.dispatch('trxbilling/getBillings', {request_date: this.month_year +'-01'});
		this.$store.dispatch('branch/getBranches');
		this.$store.dispatch('dept/getDepts');
		this.$store.dispatch('coa/getCoaDropdown');
		this.collection_date = format(new Date(), 'yyyy-MM-dd');
		this.service_data = [];
		// this.details[0].department_id = this.currUser.dept_id;
		// this.details[0].branch_id = this.currUser.branch_id;
		
	},

	methods: {
		getData(){
			this.$store.dispatch('trxbilling/getBillings', {request_date: this.month_year +'-01'});
		},
		viewReceipt(){
			if(this.receipt_type === 2){
				this.viewar = true;
			} else {
				this.viewor = true;
			}
		},
		getDeptData(dept_id, index) {
			var filterDetails = this.details;
			
			if (filterDetails) {
				this.dataDepts.filter(depts_details => {
					if(depts_details.id === dept_id){			
						if (depts_details.sections.length > 0) {
							filterDetails[index].sections = depts_details.sections;
							filterDetails[index].section_id = depts_details.dataSections.includes(filterDetails[index].section_id) ? filterDetails[index].section_id : '' ;
						} else {
							filterDetails[index].sections = [];
							filterDetails[index].section_id = 0;
						}
					}

					if(dept_id == 1 || dept_id == 25 || dept_id == 26 || dept_id == 29){
						filterDetails[index].branches = this.branches.filter(e => e.brnc_stat === 'OPEN');
					} else {
						filterDetails[index].branches = this.branches.filter(det => det.brnc_code === 'HOB' && det.brnc_stat === 'OPEN');
					}
				});
			}
		},
		close() {
			setTimeout(() => {
				this.$refs.form.reset();
				this.$refs.form.resetValidation();
				this.$store.dispatch('trxcollection/setValid', false);
				this.$store.dispatch('trxcollection/setDialog', false);
				this.$store.dispatch('trxcollection/setEditedIndex', -1);
				this.isvalid = false;
			}, 2000);
		},
		getReceiptNumber(){
			let get_generated_number = '';
			let payload = { document_date : this.collection_date };
			if(this.receipt_type === 1){
				get_generated_number = this.$store.dispatch('trxdisb/getDocumentNumberOR', payload).then(response => {
					if(response.data.data.length === 0){
						this.snackbar = true;
						this.$data.error_message = 'No series for Official Receipt.';
						this.receipt_type = 0;
						this.receipt_number = '';
					} else {
						if(this.or_number){
							console.log(this.or_number);
							this.receipt_number = this.or_number;
						} else {
							this.receipt_number = response.data.data[0].or_number;
							this.$store.dispatch('trxdisb/setOR', response.data.data[0].or_number);
						}
					}
				});
			} else if(this.receipt_type === 2){
				get_generated_number = this.$store.dispatch('trxdisb/getDocumentNumberAR', payload).then(response => {
					if(response.data.data.length === 0){
						this.snackbar = true;
						this.$data.error_message = 'No series for Acknowledgement Receipt.';
						this.receipt_type = 0;
						this.receipt_number = '';
					} else {
						if(this.ar_number){
							this.receipt_number = this.ar_number;
						} else {
							this.receipt_number = response.data.data[0].ar_number;
							this.$store.dispatch('trxdisb/setAR', response.data.data[0].ar_number);
						}
					}
				});
			}
			console.log(get_generated_number);
		},
		async getDataDepts(){
			this.dataDepts = this.depts.data;
			this.nextPageDepts = this.depts.next_page_url;
			let page = 2;

			while (this.nextPage !== null){
				await this.$store.dispatch('dept/getData', page).then(response => {
					this.nextPage = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataDepts.push(details);
					});
				});
				page++;
			}
			let dept = this.dataDepts.filter(e => e.dept_status === 1);
			this.dataDepts = dept;

		},
		async getDataCoas(){
			if(this.coaData.length === 0){
				this.dataCoas = this.coas.data;
				this.nextPageCoas = this.coas.next_page_url;
				let page = 2;

				while (this.nextPageCoas !== null){
					await this.$store.dispatch('coa/getCoaData', page).then(response => {
						this.nextPageCoas = response.data.data.next_page_url;
						response.data.data.data.forEach(details => {
							this.dataCoas.push(details);
						});
					});
					page++;
				}
				
				let data = this.dataCoas.filter(e => parseInt(e.acct_status) === 1);
				this.$store.commit('coa/SET_COA_DATA', data);
			}
		},
		async save() {
			this.$store.dispatch('trxcollection/setValid',this.$refs.form.validate());
			this.isvalid = this.$refs.form.validate();
			if(this.isvalid){
				
				let headers = [];
				let details = [];
				let total_amount = 0;

				this.details.forEach(det => {
					let debit_amount = det.debit_amount.replace(/,/g, '');
					let credit_amount = det.credit_amount.replace(/,/g, '');
					total_amount = parseFloat(total_amount) + parseFloat(debit_amount);
					details.push(
						{
							'collection_number': this.collection_number,
							'collection_date': this.collection_date,
							'payee_id': this.payee_id,
							'department_id': det.department_id,
							'branch_id': det.branch_id,
							'section_id': det.section_id ? det.section_id : 0,
							'account_code': det.account_code,
							'debit_amount': parseFloat(debit_amount).toFixed(2),
							'credit_amount': parseFloat(credit_amount).toFixed(2),
							'edit_by': this.currUser.id,
							'remarks': det.remarks,
							'status': 3
						}
					);
				});
				let header = {
					'collection_number': this.collection_number, 
					'collection_date': this.collection_date,
					'receipt_type': this.receipt_type,
					'receipt_no': this.receipt_number,
					'amount': total_amount,
					'accounting_status': 3,
					'edit_by': this.currUser.id,
					'billing_id': this.billing_id,
					'payee_id': this.payee_id
				};

				headers.push(header);
				let data = {};
				data.headers = headers;
				data.details = details;
				console.log(data);
				data.image_title = [];
				data.upload_file = [];

				for( var i = 0; i < this.upload_files.length; i++ ){
					let file = this.upload_files[i];
					data.image_title.push(file.name);
					data.upload_file.push(file);
				}

				var options = {
					initialFormData: new FormData(),
					showLeafArrayIndexes: true,
					includeNullValues: false,
					mapping: function(value) {
						if (typeof value === 'boolean') {
							return +value ? '1': '0';
						}
						return value;
					}
				};

				var convertedFormData = jsonToFormData(data, options);
				this.$store.dispatch('trxcollection/saveCollection', {type: this.receipt_type,value:convertedFormData});
			} else {
				this.createDialog = false;
				this.$store.commit('app/SET_SNACK_BAR', { 
					'status': true, 
					'message': 'Please fill out all the required fields.', 
					'messagetype': 'error' 
				}, { root: true });
			}
		},
		async setDetails(){
			await this.$store.dispatch('trxbilling/getBillingData', this.invoice_no).then(response => {
				let data = response.data.data;
				let payees = this.payees.filter(det => det.id === data[0].payee_id);
				// let collected_amount =  data.amount_collected;
				this.collected_amount =  numeral(data[0].amount_collected).format('0,0.00');
				this.billing_id = data[0].id;
				this.payee_id = payees[0].id;
				this.payee_desc = payees[0].payee_desc;
				this.due_date = format(new Date(data[0].due_date), 'yyyy-MM-dd');
				this.billing_amount = numeral(data[0].amount).format('0,0.[00]');
				this.invoice_data = data;
				this.details = data[0].details.filter(det => det.debit_amount !== '0.0000');
				this.details.forEach((det,key) => {
					det.amount = det.debit_amount;
					det.credit_amount = det.debit_amount;
					det.debit_amount = 0;
					this.getDeptData(det.department_id, key);
				});
				this.billing_details = data[0].details;
			
				// this.service_data = this.services.filter(det => det.payee_id === data[0].payee_id && det.service_tag === 2);
			});
		},
		async payeeDetails(){
			// this.payee_id
			// let data = this.payees.filter(det => det.id === this.payee_id);
			await this.$store.dispatch('payefile/getPayeeDet', this.payee_id).then(response => {
				let data = response.data.data;
				this.payee_data = data;
			});	
		},
		handleFileUploads(){
			this.upload_files = this.$refs.files.files;
		},
		addRow(){
			let det = { 
				department_id: this.details[0].department_id,
				branch_id: this.details[0].branch_id,
				section_id: this.details[0].section_id,
				sections: this.details[0].sections,
				account_code: '',
				debit_amount: 0,
				credit_amount: 0,
				remarks: 'Collected Amount'
			};
			this.details.push(det);
		},
		cancel(){
			this.$router.push('/trx-collection');
		},
		removeDetail(key) {
			this.details.filter((detail, index) => {
				if (index === key) {
					this.details.splice(key, 1);
				}
			});
		},
	}
};
</script>
<style scoped>
  .remove-underline.v-text-field .v-input__control .v-input__slot:before {
    border-style: none;
  }
  .remove-underline.v-text-field .v-input__control .v-input__slot:after {
    border-style: none;
  }

  /*Remove spinners*/
  .input_number input[type='number'] {
    -moz-appearance: textfield;
    text-align: right;
  }

  .input_number input::-webkit-outer-spin-button,
  .input_number input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

	.document-card-container.v-card--outlined,
	.document-form-container.v-card--outlined {
		-webkit-box-shadow: none !important;
    box-shadow: none !important;
	}

	.document-card-title {
		padding: 0;
	}

	.document-form-container.v-card--outlined {
		border: 0;
	}
</style>
