<template>
	<v-card style="padding:15px">
		<v-card-text>
			<div id="printArea">
				<div style="margin: auto; padding: 5px; border: 1px solid black; width: 400px; font-family: sans-serif;font-size: 12px; line-height: 20px;border-style: dashed" >
					<p style="text-align: center;">
						PETNET, INC.
						<br>
						East Offices Bldg. 114 Aguirre St.
						<br>
						Legaspi Village, Makati City
						<br>
						VAT REGISTERED TIN: 201-200-777-0000
						<br>
						Head Office
						<br>
						MIN: XXXXXX
						<br>
						SN: XXXXXX
						<br>
						ACR: XXXXX
					</p>
					<p style="text-transform:uppercase">
						CUSTOMER NAME: {{payee_data[0].payee_desc}}
						<br>
						BUSINESS STYLE:
						<br>
						ADDRESS:  {{payee_data[0].house_unit + ' ' + payee_data[0].street + ' ' + payee_data[0].barangay + ' ' + payee_data[0].municipality + ' ' + payee_data[0].city}}
						<br>
						TIN: {{payee_data[0].tin}}
					</p>
					<br>
					<p style="text-align: center;">
						<b>OFFICIAL RECEIPT</b>
						<br>
						OR No: HOB-OR0000-0000-0001
					</p><br>
					<table style="width: 398px; padding-right:10px">
						<tr>
							<td>{{invoice_data[0].remarks}}</td>
							<td style="text-align: right;">{{invoice_data[0].amount}}</td>
						</tr>
					</table>
					<br>
					<table style="width: 398px;margin: 0px; padding-right:10px">
						<tr>
							<td><b>TOTAL</b></td>
							<td style="text-align: right;"><b>{{invoice_data[0].amount}}</b></td>
						</tr>
						<tr>
							<td>VATable</td>
							<td style="text-align: right;">0.00</td>
						</tr>
						<tr>
							<td>VAT Exempt</td>
							<td style="text-align: right;">{{invoice_data[0].amount}}</td>
						</tr>
						<tr>
							<td>Zero-rated</td>
							<td style="text-align: right;">0.00</td>
						</tr>
						<tr>
							<td>VAT amount</td>
							<td style="text-align: right;">0.00</td>
						</tr>
						<tr>
							<td><b>TOTAL DUE</b></td>
							<td style="text-align: right;"><b>{{invoice_data[0].amount}}</b></td>
						</tr>
						<tr>
							<td><b>TOTAL RECEIVE</b></td>
							<td style="text-align: right;"><b>{{collected_amount}}</b></td>
						</tr>
						<tr>
							<td><b>BALANCE</b></td>
							<td style="text-align: right;"><b>{{getBalance(invoice_data[0].amount,collected_amount)}}</b></td>
						</tr>
					</table>
					<br>
					<br>
					<div style="line-height: 12px;">
						<p style="text-align: center; margin: 0px; margin-bottom: 5px">
							<b>THIS SERVES AS AN OFFICIAL RECEIPT.</b>
						</p>
						<p style="text-align: center; font-size: 9px; margin: 0px">
							THIS RECEIPT SHALL BE VALID FOR   FIVE (5) YEARS FROM
							<br>
							THE DATE OF ACKNOWLEDGMENT CERTIFICATE
						</p>
						<p style="text-align: center; text-transform: uppercase">
						{{currUser.user_first_name}} 
						{{currUser.user_last_name}}
						<br>
						{{collection_date}}
						</p>
						<p style="text-align: center; font-size: 9px;margin: 0px;">
							PETNET, INC.
							<br>
							East Offices Bldg. 114 Aguirre St.
							<br>
							Legaspi Village, Makati City
							<br>
							Acknowledgment Certificate No. XXXXX
							<br>
							Date Issued: MM/DD/YYYY
							<br>
							Series No. HOB-OR0000-0000-0001 to HOB-OR9999-9999-9999
							<br>
						</p>
						<p style="text-align: center; font-size: 9px;font-style: italic;margin: 0px;">THIS DOCUMENT IS SYSTEM GENERATED.</p>
					</div>
				</div>
			</div>
		</v-card-text>
	</v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import numeral from 'numeral';
export default {
	props: {
		invoice_data: Array,
		collected_amount: String,
		collection_date: Date,
		payee_data: Array
	},computed: {
		...mapGetters({
			currUser: 'auth/currUser',
		}),
	},
	methods:{
		getBalance(invoice_amount, collected_amount){
			let inv_amount = invoice_amount.replace(/,/g, '');
			let col_amount = collected_amount.replace(/,/g, '');
			let balance = inv_amount - col_amount;

			return numeral(balance).format('0,0.[00]');

		}
	}
};
</script>
